import { SupportedCountriesE } from '@/types/cyclone/models';

export const getPricesByCountry = (country: SupportedCountriesE) => {
  switch (country) {
    case SupportedCountriesE.CHILE:
      return {
        professional_base_price: 9900,
        venue_base_price: 19900,
        venue_staff_price: 3000,
        referral_discount: 2000,
        whatsapp_reminders: 3500
      };
    case SupportedCountriesE.URUGUAY:
      return {
        professional_base_price: 399,
        venue_base_price: 699,
        venue_staff_price: 150,
        referral_discount: 100,
        whatsapp_reminders: 120
      };
    default:
      return {
        professional_base_price: 6900,
        venue_base_price: 10900,
        venue_staff_price: 3000,
        referral_discount: 1000,
        whatsapp_reminders: 4000
      };
  }
};
