const IMAGE_CDN_URL = process.env.NEXT_PUBLIC_IMAGE_CDN_URL;

export const professionals = [
  {
    name: 'Beauty Alice',
    title: 'Centro de depilación',
    image: `${IMAGE_CDN_URL}/landing/profesional_agora_9.webp`,
    url: '/beautyalice'
  },
  {
    name: 'Espacio Psicope',
    title: 'Centro de salud',
    image: `${IMAGE_CDN_URL}/landing/profesional_agora_18.webp`,
    url: '/espaciopsicope'
  },
  {
    name: 'Melani Singh',
    title: 'Estudio de belleza',
    image: `${IMAGE_CDN_URL}/landing/profesional_agora_17.webp`,
    url: '/estudioms'
  },
  {
    name: 'Nadia Salina',
    title: 'Academia de cejas',
    image: `${IMAGE_CDN_URL}/landing/profesional_agora_6.webp`,
    url: '/nadiasalinaacademia'
  },
  {
    name: 'Centro Podologico Norte',
    title: 'Centro podológico',
    image: `${IMAGE_CDN_URL}/landing/profesional_agora_13.webp`,
    url: '/centropodologiconort'
  }
];

export type ProfessionalType = (typeof professionals)[number];
